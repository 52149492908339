import {
	CurrencyUnitOptionsEnum,
	CustomPropertyDatatype,
	CustomPropertyInterface,
	CustomPropertyLastModifiedBy,
	CustomPropertyType,
	CustomPropertyValueType,
	MeasureDistanceUnitOptionsEnum,
	MeasureWeightUnitOptionsEnum,
	ProcessPropertyStatusEnum,
	UnitOptionType,
} from './type.interface';

export const defaultValueFromCustomPropertyType: {
	[key in CustomPropertyType]: {
		value: CustomPropertyValueType;
		lastSuccess?: number;
		visible: boolean;
		lastModifiedBy: CustomPropertyLastModifiedBy;
		createdAt: number;
		updatedAt: number;
		unit?: UnitOptionType;
	};
} = {
	[CustomPropertyType.BOOLEAN]: {
		value: false,
		visible: true,
		lastModifiedBy: CustomPropertyLastModifiedBy.USER,
		createdAt: Date.now(),
		updatedAt: Date.now(),
	},
	[CustomPropertyType.CURRENCY]: {
		value: 0,
		visible: true,
		lastModifiedBy: CustomPropertyLastModifiedBy.USER,
		createdAt: Date.now(),
		updatedAt: Date.now(),
		unit: CurrencyUnitOptionsEnum.EURO,
	},
	[CustomPropertyType.DATE]: {
		value: Date.now(),
		visible: true,
		lastModifiedBy: CustomPropertyLastModifiedBy.USER,
		createdAt: Date.now(),
		updatedAt: Date.now(),
	},
	[CustomPropertyType.EMAIL]: {
		value: [],
		visible: true,
		lastModifiedBy: CustomPropertyLastModifiedBy.USER,
		createdAt: Date.now(),
		updatedAt: Date.now(),
	},
	[CustomPropertyType.URL]: {
		value: [],
		visible: true,
		lastModifiedBy: CustomPropertyLastModifiedBy.USER,
		createdAt: Date.now(),
		updatedAt: Date.now(),
	},
	[CustomPropertyType.NAME]: {
		value: [],
		visible: true,
		lastModifiedBy: CustomPropertyLastModifiedBy.USER,
		createdAt: Date.now(),
		updatedAt: Date.now(),
	},
	[CustomPropertyType.MEASURE_DISTANCE]: {
		value: 0,
		visible: true,
		lastModifiedBy: CustomPropertyLastModifiedBy.USER,
		createdAt: Date.now(),
		updatedAt: Date.now(),
		unit: MeasureDistanceUnitOptionsEnum.METER,
	},
	[CustomPropertyType.MEASURE_WEIGHT]: {
		value: 0,
		visible: true,
		lastModifiedBy: CustomPropertyLastModifiedBy.USER,
		createdAt: Date.now(),
		updatedAt: Date.now(),
		unit: MeasureWeightUnitOptionsEnum.GRAM,
	},
	[CustomPropertyType.MULTI_SELECT_TAG]: {
		value: [],
		visible: true,
		lastModifiedBy: CustomPropertyLastModifiedBy.USER,
		createdAt: Date.now(),
		updatedAt: Date.now(),
	},
	[CustomPropertyType.MULTI_SELECT_SOURCE]: {
		value: [],
		visible: true,
		lastModifiedBy: CustomPropertyLastModifiedBy.USER,
		createdAt: Date.now(),
		updatedAt: Date.now(),
	},
	[CustomPropertyType.PERCENTAGE]: {
		value: 0,
		visible: true,
		lastModifiedBy: CustomPropertyLastModifiedBy.USER,
		createdAt: Date.now(),
		updatedAt: Date.now(),
	},
	[CustomPropertyType.PHONE]: {
		value: [],
		visible: true,
		lastModifiedBy: CustomPropertyLastModifiedBy.USER,
		createdAt: Date.now(),
		updatedAt: Date.now(),
	},
	[CustomPropertyType.PLAIN_NUMBER]: {
		value: 0,
		visible: true,
		lastModifiedBy: CustomPropertyLastModifiedBy.USER,
		createdAt: Date.now(),
		updatedAt: Date.now(),
	},
	[CustomPropertyType.PLAIN_TEXT]: {
		value: '',
		visible: true,
		lastModifiedBy: CustomPropertyLastModifiedBy.USER,
		createdAt: Date.now(),
		updatedAt: Date.now(),
	},
	[CustomPropertyType.SELECT_IMPORTANCE]: {
		value: '0',
		visible: true,
		lastModifiedBy: CustomPropertyLastModifiedBy.USER,
		createdAt: Date.now(),
		updatedAt: Date.now(),
	},
	[CustomPropertyType.SELECT_STAR]: {
		value: '0',
		visible: true,
		lastModifiedBy: CustomPropertyLastModifiedBy.USER,
		createdAt: Date.now(),
		updatedAt: Date.now(),
	},
	[CustomPropertyType.SELECT_TAG]: {
		value: '',
		visible: true,
		lastModifiedBy: CustomPropertyLastModifiedBy.USER,
		createdAt: Date.now(),
		updatedAt: Date.now(),
	},
	[CustomPropertyType.TIME]: {
		value: 0,
		visible: true,
		lastModifiedBy: CustomPropertyLastModifiedBy.USER,
		createdAt: Date.now(),
		updatedAt: Date.now(),
	},
	[CustomPropertyType.LOCATION]: {
		value: { location: { lat: 0, lon: 0 }, locationString: '' },
		visible: true,
		lastModifiedBy: CustomPropertyLastModifiedBy.USER,
		createdAt: Date.now(),
		updatedAt: Date.now(),
	},
	[CustomPropertyType.USER]: {
		value: [],
		visible: true,
		lastModifiedBy: CustomPropertyLastModifiedBy.USER,
		createdAt: Date.now(),
		updatedAt: Date.now(),
	},
	[CustomPropertyType.PROCESS]: {
		value: ProcessPropertyStatusEnum.PENDING,
		lastSuccess: undefined,
		visible: true,
		lastModifiedBy: CustomPropertyLastModifiedBy.USER,
		createdAt: Date.now(),
		updatedAt: Date.now(),
	},
};

export const nodeCustomPropertyKeyIcon: { [key in CustomPropertyType]: string } = {
	[CustomPropertyType.BOOLEAN]: 'icon-tick-square',
	[CustomPropertyType.CURRENCY]: 'icon-currency',
	[CustomPropertyType.DATE]: 'icon-date',
	[CustomPropertyType.EMAIL]: 'icon-email',
	[CustomPropertyType.URL]: 'icon-www-url',
	[CustomPropertyType.NAME]: 'icon-list-bullet',
	[CustomPropertyType.MEASURE_DISTANCE]: 'icon-length',
	[CustomPropertyType.MEASURE_WEIGHT]: 'icon-weight',
	[CustomPropertyType.MULTI_SELECT_SOURCE]: 'icon-multi-select',
	[CustomPropertyType.MULTI_SELECT_TAG]: 'icon-multi-select',
	[CustomPropertyType.PERCENTAGE]: 'icon-percent',
	[CustomPropertyType.PHONE]: 'icon-phone',
	[CustomPropertyType.PLAIN_NUMBER]: 'icon-number',
	[CustomPropertyType.PLAIN_TEXT]: 'icon-text',
	[CustomPropertyType.SELECT_IMPORTANCE]: 'icon-diamond-normal',
	[CustomPropertyType.SELECT_STAR]: 'icon-star',
	[CustomPropertyType.SELECT_TAG]: 'icon-select',
	[CustomPropertyType.TIME]: 'icon-clock',
	[CustomPropertyType.LOCATION]: 'icon-location',
	[CustomPropertyType.USER]: 'icon-contact',
	[CustomPropertyType.PROCESS]: 'icon-rich-bot',
};

export const unitsInfoMap = {
	[CustomPropertyType.CURRENCY]: {
		[CurrencyUnitOptionsEnum.ANY]: {
			icon: 'icon-currency',
			symbol: '',
		},
		[CurrencyUnitOptionsEnum.EURO]: {
			icon: 'icon-euro',
			symbol: '€',
		},
		[CurrencyUnitOptionsEnum.USA_DOLLAR]: {
			icon: 'icon-dollar',
			symbol: '$',
		},
		[CurrencyUnitOptionsEnum.POUND]: {
			icon: 'icon-gbp',
			symbol: '£',
		},
		[CurrencyUnitOptionsEnum.JAPANESE_YEN]: {
			icon: 'icon-jpy',
			symbol: '¥',
		},
		[CurrencyUnitOptionsEnum.SWISS_FRANC]: {
			icon: 'icon-chf',
			symbol: 'Fr.',
		},
		[CurrencyUnitOptionsEnum.CANADIAN_DOLLAR]: {
			icon: 'icon-dollar',
			symbol: '$',
		},
		[CurrencyUnitOptionsEnum.CHINESE_YUAN]: {
			icon: 'icon-jpy',
			symbol: '¥',
		},
	},
	[CustomPropertyType.MEASURE_WEIGHT]: {
		[MeasureWeightUnitOptionsEnum.KILOGRAM]: {
			icon: 'icon-kilogram',
			symbol: 'kg',
		},
		[MeasureWeightUnitOptionsEnum.GRAM]: {
			icon: 'icon-gram',
			symbol: 'g',
		},
		[MeasureWeightUnitOptionsEnum.MILLIGRAM]: {
			icon: 'icon-milligram',
			symbol: 'mg',
		},
	},
	[CustomPropertyType.MEASURE_DISTANCE]: {
		[MeasureDistanceUnitOptionsEnum.KILOMETER]: {
			icon: 'icon-kilometer',
			symbol: 'km',
		},
		[MeasureDistanceUnitOptionsEnum.METER]: {
			icon: 'icon-meter',
			symbol: 'm',
		},
		[MeasureDistanceUnitOptionsEnum.CENTIMETER]: {
			icon: 'icon-centimeter',
			symbol: 'cm',
		},
		[MeasureDistanceUnitOptionsEnum.MILLIMETER]: {
			icon: 'icon-millimeter',
			symbol: 'mm',
		},
	},
};

export const convertTypeMap = (
	typeMap: Record<string, CustomPropertyType>
): Record<string, CustomPropertyDatatype> => {
	const result: Record<string, CustomPropertyDatatype> = {};
	Object.keys(typeMap).forEach((key) => {
		result[key] = typeToDataType[typeMap[key]];
	});
	return result;
};

export const convertPropertyMap = (
	propertyMap: Record<string, CustomPropertyInterface>
): Record<string, CustomPropertyDatatype> => {
	const result: Record<string, CustomPropertyDatatype> = {};
	Object.keys(propertyMap).forEach((key) => {
		result[key] = typeToDataType[propertyMap[key].type];
	});
	return result;
};

export const typeToDataType: { [key in CustomPropertyType]: CustomPropertyDatatype } = {
	[CustomPropertyType.PLAIN_TEXT]: CustomPropertyDatatype.STRING,
	[CustomPropertyType.PLAIN_NUMBER]: CustomPropertyDatatype.NUMBER,
	[CustomPropertyType.SELECT_TAG]: CustomPropertyDatatype.SELECT,
	[CustomPropertyType.DATE]: CustomPropertyDatatype.TIMESTAMP,
	[CustomPropertyType.TIME]: CustomPropertyDatatype.NUMBER,
	[CustomPropertyType.MULTI_SELECT_TAG]: CustomPropertyDatatype.MULTI_SELECT,
	[CustomPropertyType.SELECT_STAR]: CustomPropertyDatatype.SELECT,
	[CustomPropertyType.PHONE]: CustomPropertyDatatype.MULTI_STRING,
	[CustomPropertyType.EMAIL]: CustomPropertyDatatype.MULTI_STRING,
	[CustomPropertyType.URL]: CustomPropertyDatatype.MULTI_STRING,
	[CustomPropertyType.NAME]: CustomPropertyDatatype.MULTI_STRING,
	[CustomPropertyType.CURRENCY]: CustomPropertyDatatype.UNIT_NUMBER,
	[CustomPropertyType.BOOLEAN]: CustomPropertyDatatype.BOOLEAN,
	[CustomPropertyType.MEASURE_WEIGHT]: CustomPropertyDatatype.UNIT_NUMBER,
	[CustomPropertyType.MEASURE_DISTANCE]: CustomPropertyDatatype.UNIT_NUMBER,
	[CustomPropertyType.SELECT_IMPORTANCE]: CustomPropertyDatatype.SELECT,
	[CustomPropertyType.MULTI_SELECT_SOURCE]: CustomPropertyDatatype.MULTI_SELECT,
	[CustomPropertyType.PERCENTAGE]: CustomPropertyDatatype.NUMBER,
	[CustomPropertyType.LOCATION]: CustomPropertyDatatype.GEOLOCATION,
	[CustomPropertyType.USER]: CustomPropertyDatatype.MULTI_STRING,
	[CustomPropertyType.PROCESS]: CustomPropertyDatatype.PROCESS,
};
