export enum Language {
	EN_GB = 'en-gb',
	ES_ES = 'es-es',
	FR_FR = 'fr-fr',
}

// LANGUAGE-CODE TO OUR CODE
export enum BrowserLanguage {
	'EN' = 'EN_GB',
	'EN-US' = 'EN_GB',
	'ES-ES' = 'ES_ES',
	'FR' = 'FR_FR',
	'FR-FR' = 'FR_FR',
}
