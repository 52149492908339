import { Injectable } from '@angular/core';
import {
	BooleanEnum,
	GetNodeEdgesResponse,
	GetNodeResponse,
	JobInterface,
	JobStatusEnum,
	NoteInterface,
	PipelineInterface,
	SearchNodeResponse,
} from '@reach/interfaces';
import { BaseApiService } from '~app-client/api/_internal';
import { ReachNodeId } from '~app-client/core/types';

@Injectable({ providedIn: 'root' })
export class NodesApiService extends BaseApiService {
	protected commonPath = ['nodes'];

	public getMany(ids: ReachNodeId[]): Promise<SearchNodeResponse[]> {
		if (ids.length === 0) {
			return new Promise<SearchNodeResponse[]>((res) => res([]));
		}

		const body = {
			nodeIds: ids,
		};
		return this.http.post<SearchNodeResponse[]>(this.url('get-nodes'), body);
	}

	public getNodeInfo(nodeId: ReachNodeId): Promise<GetNodeResponse> {
		const params = {
			t: this.paramTime,
		};
		return this.http.get<GetNodeResponse>(this.url(nodeId), params);
	}

	public getRelated(
		nodeId: ReachNodeId,
		offset: number,
		limit: number,
		query: string
	): Promise<GetNodeEdgesResponse> {
		const cleanQuery = `${query || ''}`;
		const body = {
			page: Math.floor(offset / limit) || 0,
			size: limit,
			searchParams: { query: cleanQuery },
		};

		return this.http.post<GetNodeEdgesResponse>(this.url(nodeId, 'get-edges'), body);
	}

	public getJobs(nodeId: ReachNodeId): Promise<JobInterface[]> {
		const params = {
			t: `${this.paramTime}`,
			status: [JobStatusEnum.IN_PROGRESS, JobStatusEnum.PENDING],
			returnLastJobs: BooleanEnum.YES,
		};

		return this.http.get<JobInterface[]>(this.url(nodeId, 'jobs'), params);
	}

	public changeWikipediaPage(
		nodeId: ReachNodeId,
		body: { wikipediaUrl?: string; description?: string; imageUrl?: string }
	): Promise<GetNodeResponse> {
		return this.http.post<GetNodeResponse>(this.url(nodeId, 'wikipedia'), body);
	}

	public getContent(nodeId: ReachNodeId): Promise<{
		content: string;
		isEmpty: boolean;
		isLoading: boolean;
	}> {
		const query = {
			t: `${this.cacheTimer}`,
		};
		return this.http.get<{
			content: string;
			isEmpty: boolean;
			isLoading: boolean;
		}>(this.url(nodeId, 'content'), query);
	}

	public getSummary(nodeId: ReachNodeId): Promise<{
		summary: string;
		isEmpty: boolean;
		isLoading: boolean;
	}> {
		const query = {
			t: this.paramTime,
		};
		return this.http.get<{
			summary: string;
			isEmpty: boolean;
			isLoading: boolean;
		}>(this.url(nodeId, 'summary'), query);
	}

	public convertContentToNote(nodeId: ReachNodeId): Promise<NoteInterface> {
		return this.http.post<NoteInterface>(this.url(nodeId, 'to-note'));
	}

	public processSummaryAndKeyphrases(
		nodesIds: ReachNodeId[]
	): Promise<{ jobId: string; nodeId: ReachNodeId }[]> {
		const body = {
			nodeIds: nodesIds,
		};
		return this.http.post<{ jobId: string; nodeId: ReachNodeId }[]>(
			this.url('process-summary-keyphrases'),
			body
		);
	}

	public learnNodes(nodesIds: ReachNodeId[]): Promise<{ pipeline: PipelineInterface }> {
		const body = {
			nodeIds: nodesIds,
		};
		return this.http.post<{ pipeline: PipelineInterface }>(this.url('learn'), body);
	}

	public learnAll(): Promise<void> {
		return this.http.post<void>(this.url('learn-all'));
	}

	public updateContent(nodeId: ReachNodeId, content: string): Promise<void> {
		return this.http.put<void>(this.url(nodeId, 'content'), { content });
	}
}
