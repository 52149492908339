export type ReplaceLeavesForTransformerFn<PROP, RET> = (value: PROP) => RET;

export type ReplaceLeavesForTransformer<TRANSFORMER_TYPE, TYPE> = TYPE extends object
	? TYPE extends Array<infer _>
		? ReplaceLeavesForTransformerFn<TRANSFORMER_TYPE, TYPE>
		: {
				[TYPE_KEY in keyof TYPE]: ReplaceLeavesForTransformer<
					TRANSFORMER_TYPE,
					TYPE[TYPE_KEY]
				>;
		  }
	: TYPE extends boolean
	? ReplaceLeavesForTransformerFn<TRANSFORMER_TYPE, boolean>
	: ReplaceLeavesForTransformerFn<TRANSFORMER_TYPE, TYPE>;
