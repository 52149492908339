import { Injectable } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class MediaQueryService {
	public static desktopScreenQuery = '(min-width: 820px) and (min-height: 600px)';
	public static prefersDarkMode = '(prefers-color-scheme: dark)';

	public static canHover = '(hover: hover)';

	public static getCurrent(query: string): boolean {
		return window.matchMedia(query).matches;
	}

	private readonly queryMap: Record<string, Observable<boolean>> = {};

	public getQuery$(query: string): Observable<boolean> {
		const existing = this.queryMap[query];
		if (existing) {
			return existing;
		}

		const matchMedia = window.matchMedia(query);
		const obs = fromEvent(matchMedia, 'change').pipe(
			startWith(matchMedia),
			map((event) => event as MediaQueryListEvent),
			map((event) => event.matches)
		);
		this.queryMap[query] = obs;

		return obs;
	}
}
