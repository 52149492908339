import { inject, Injectable } from '@angular/core';
import { HotToastService } from '@ngneat/hot-toast';
import { TranslocoService } from '@ngneat/transloco';
import {
	AnyImportCsvNode,
	BrowserBookmarksBrowser,
	ErrorCodes,
	GoogleDriveIntegrationFilesImportInterface,
	GoogleDriveResponseAuthorizeUserInterface,
	ImportEvernoteListNotebooksInterface,
	IntegrationExecutionInterface,
	IntegrationInterface,
	IntegrationListInterface,
	JobInterface,
	LocationLatLonWithPrecision,
	MapsPinsListRequestType,
	NodeCreationSource,
	PhoneContactsRequestType,
	PocketGetCodeResponse,
	PocketIntegrationAccountWithoutSensitive,
	ReachBookmark,
	ReachIntegration,
	ZapierIntegrationInterface,
} from '@reach/interfaces';
import { Observable } from 'rxjs';
import {
	ReachImport,
	ReachIntegrationOrImport,
} from '~app-client-item/integrations/types/integrations-imports.type';
import { BaseApiService } from '~app-client/api/_internal';
import { ReachBlobDownloaderService } from '~app-client/core/services';
import { CLIENT_ENVIRONMENT } from '~app-client/core/tokens';
import { ReachNodeId } from '~app-client/core/types';
import { ClientApiError } from '../types/error';

@Injectable({ providedIn: 'root' })
export class IntegrationsApiService extends BaseApiService {
	protected commonPath = ['integrations'];
	protected commonIntegrationPath = ['integrations'];
	protected integrationsUrl = this.urlFnGenerator(this.commonIntegrationPath);
	protected commonNotesPath = ['notes'];
	protected notesUrl = this.urlFnGenerator(this.commonNotesPath);

	private readonly blobDownloaderService = inject(ReachBlobDownloaderService);
	private readonly hotToastService = inject(HotToastService);
	private readonly transloco = inject(TranslocoService);

	protected readonly environment = inject(CLIENT_ENVIRONMENT);

	public getList(): Promise<IntegrationListInterface> {
		return this.http.get<IntegrationListInterface>(this.integrationsUrl(), {
			t: this.paramTime,
		});
	}

	public getIntegration(integrationId: string): Promise<IntegrationInterface> {
		return this.http.get<IntegrationInterface>(this.integrationsUrl(integrationId), {
			t: this.paramTime,
		});
	}

	public getIntegrationAsObs(integrationId: string): Observable<IntegrationInterface> {
		return this.http.getObs<IntegrationInterface>(this.integrationsUrl(integrationId), {
			t: this.paramTime,
		});
	}

	public pocketGetAuthUrl(redirectUrl: string, codeParamName: string): Promise<string> {
		return this.http
			.post<PocketGetCodeResponse>(this.integrationsUrl('pocket', 'get-auth-url'), {
				redirectUrl,
				codeParamName,
				t: this.paramTime,
			})
			.then((res) => res.authUrl);
	}

	public evernoteGetAuthUrl(
		callbackUrl: string
	): Promise<{ authUrl: string; oauthTokenSecret: string }> {
		const body = { callbackUrl };
		return this.http.post<{ authUrl: string; oauthTokenSecret: string }>(
			this.integrationsUrl('import-evernote', 'get-auth-url'),
			body
		);
	}

	public evernoteGetAuthToken(body: {
		oauthToken: string;
		oauthVerifier: string;
		tokenSecret: string;
	}): Promise<string> {
		return this.http
			.post<{
				accessToken: string;
			}>(this.integrationsUrl('import-evernote', 'get-access-token'), body)
			.then((response) => response.accessToken);
	}

	public evernoteGetNotebooks(
		accessToken: string
	): Promise<ImportEvernoteListNotebooksInterface[]> {
		const query = { accessToken };
		return this.http.get<ImportEvernoteListNotebooksInterface[]>(
			this.integrationsUrl('import-evernote', 'list-notebooks'),
			query
		);
	}

	public createEvernoteIntegration(
		accessToken: string,
		notebooksIds: string[],
		overwriteExistingNodes: boolean
	): Promise<IntegrationExecutionInterface> {
		const body = {
			accessToken,
			guids: notebooksIds,
			overwriteExistingNodes,
		};

		return this.http.post<IntegrationExecutionInterface>(
			this.integrationsUrl('import-evernote', 'import-evernote'),
			body
		);
	}

	public createZapierIntegration(code: string): Promise<ZapierIntegrationInterface> {
		return this.http
			.post<ZapierIntegrationInterface>(this.integrationsUrl('zapier'), {
				code,
			})
			.catch(this.handleSameExecutionRunningError(ReachIntegration.ZAPIER));
	}

	public createPocketIntegration(
		code: string
	): Promise<PocketIntegrationAccountWithoutSensitive> {
		return this.http
			.post<PocketIntegrationAccountWithoutSensitive>(this.integrationsUrl('pocket'), {
				code,
			})
			.catch(this.handleSameExecutionRunningError(ReachIntegration.POCKET));
	}

	public createCsvIntegration(
		workspaceName: string,
		items: AnyImportCsvNode[],
		isFirstBatch: boolean,
		location?: LocationLatLonWithPrecision
	): Promise<IntegrationExecutionInterface> {
		const body: {
			items: AnyImportCsvNode[];
			location?: LocationLatLonWithPrecision;
			fileName: string;
			isFirstBatch: boolean;
		} = {
			items,
			fileName: workspaceName,
			isFirstBatch,
		};
		if (location) {
			body.location = location;
		}
		return this.http
			.post<IntegrationExecutionInterface>(this.integrationsUrl('csv'), body)
			.catch(this.handleSameExecutionRunningError(ReachIntegration.CSV));
	}

	public async downloadCsvTemplate(fileName: string): Promise<Blob> {
		const headers = { ['Content-Type']: 'text/csv; charset=utf-8' };
		const blob = await this.http.post<Blob>(
			this.integrationsUrl('csv', 'template'),
			{},
			{},
			headers,
			'blob'
		);

		await this.blobDownloaderService.downloadBlob(blob, `${fileName}.csv`);
		return blob;
	}

	public executePocketIntegration(integrationId: string): Promise<IntegrationExecutionInterface> {
		return this.http.post<IntegrationExecutionInterface>(
			this.integrationsUrl('pocket', 'execute'),
			{
				integrationId,
			}
		);
	}

	public getIntegrationExecutions(
		integrationId: string
	): Promise<IntegrationExecutionInterface[]> {
		return this.http.get<IntegrationExecutionInterface[]>(
			this.integrationsUrl(integrationId, 'executions'),
			{
				t: this.paramTime,
			}
		);
	}

	public deleteIntegration(integrationId: string): Promise<void> {
		return this.http.delete<void>(this.integrationsUrl(integrationId));
	}

	public executePhoneContacts(
		body: PhoneContactsRequestType
	): Promise<IntegrationExecutionInterface> {
		return this.http
			.post<IntegrationExecutionInterface>(this.integrationsUrl('contacts'), body)
			.catch(this.handleSameExecutionRunningError(ReachIntegration.PHONE_CONTACTS));
	}

	public executeMapsPinsList(
		body: MapsPinsListRequestType
	): Promise<IntegrationExecutionInterface> {
		return this.http
			.post<IntegrationExecutionInterface>(this.integrationsUrl('maps-pin-list'), body)
			.catch(this.handleSameExecutionRunningError(ReachIntegration.MAPS_PINS_LIST));
	}

	public executeGoogleDocs(
		files: File[],
		isFirstBatch: boolean,
		workspaceIds: ReachNodeId[],
		source: NodeCreationSource,
		location?: LocationLatLonWithPrecision
	): Promise<IntegrationExecutionInterface> {
		const body = new FormData();
		for (const file of files) {
			body.append('files', file);
		}

		body.append('source', JSON.stringify({ source }));
		body.append('workspaceIds', JSON.stringify(workspaceIds));
		body.append('fileCreate', JSON.stringify({ location }));
		body.append('isFirstBatch', JSON.stringify({ isFirstBatch }));

		return this.http
			.post<IntegrationExecutionInterface>(this.integrationsUrl('google-docs'), body)
			.catch(this.handleSameExecutionRunningError(ReachIntegration.GOOGLE_DOCS));
	}

	public executeQuip(
		files: File[],
		workspaceIds: ReachNodeId[],
		source: NodeCreationSource,
		location?: LocationLatLonWithPrecision
	): Promise<IntegrationExecutionInterface> {
		const body = new FormData();
		for (const file of files) {
			body.append('files', file);
		}

		body.append('source', JSON.stringify({ source }));
		body.append('workspaceIds', JSON.stringify(workspaceIds));
		body.append('fileCreate', JSON.stringify({ location }));

		return this.http
			.post<IntegrationExecutionInterface>(this.integrationsUrl('quip'), body)
			.catch(this.handleSameExecutionRunningError(ReachIntegration.QUIP));
	}
	public executeDropboxPaper(
		files: File[],
		workspaceIds: ReachNodeId[],
		source: NodeCreationSource,
		location?: LocationLatLonWithPrecision
	): Promise<IntegrationExecutionInterface> {
		const body = new FormData();
		for (const file of files) {
			body.append('files', file);
		}

		body.append('source', JSON.stringify({ source }));
		body.append('workspaceIds', JSON.stringify(workspaceIds));
		body.append('fileCreate', JSON.stringify({ location }));

		return this.http
			.post<IntegrationExecutionInterface>(this.integrationsUrl('dropbox-paper'), body)
			.catch(this.handleSameExecutionRunningError(ReachIntegration.DROPBOX_PAPER));
	}

	public executeMarkdown(
		files: File[],
		workspaceIds: ReachNodeId[],
		source: NodeCreationSource,
		location?: LocationLatLonWithPrecision
	): Promise<JobInterface[]> {
		const body = new FormData();
		for (const file of files) {
			body.append('files', file);
		}

		body.append('source', JSON.stringify({ source }));
		body.append('workspaceIds', JSON.stringify(workspaceIds));
		body.append('fileCreate', JSON.stringify({ location }));

		return this.http
			.post<JobInterface[]>(this.notesUrl('create-from-markdown'), body)
			.catch(this.handleSameExecutionRunningError(ReachImport.MARKDOWN));
	}

	public executeMicrosoftWord(
		files: File[],
		workspaceIds: ReachNodeId[],
		source: NodeCreationSource,
		location?: LocationLatLonWithPrecision
	): Promise<JobInterface[]> {
		const body = new FormData();
		for (const file of files) {
			body.append('files', file);
		}

		body.append('source', JSON.stringify({ source }));
		body.append('workspaceIds', JSON.stringify(workspaceIds));
		body.append('fileCreate', JSON.stringify({ location }));

		return this.http
			.post<JobInterface[]>(this.notesUrl('create-from-docx'), body)
			.catch(this.handleSameExecutionRunningError(ReachImport.WORD_DOCX));
	}

	public executeBookmarks(
		bookmarks: ReachBookmark[],
		browser: BrowserBookmarksBrowser
	): Promise<IntegrationExecutionInterface> {
		const body = { bookmarks, browser };
		return this.http.post<IntegrationExecutionInterface>(
			this.integrationsUrl('import-bookmarks'),
			body
		);
	}

	private handleSameExecutionRunningError(integration: ReachIntegrationOrImport) {
		return (err: ClientApiError) => {
			console.log('DEBUG: ', err);
			if (
				err.isReachError &&
				err.error?.code ===
					ErrorCodes.INTEGRATION_EXECUTION_CANNOT_CREATE_INTEGRATION_EXECUTION_UNTIL_LAST_FINISHES
			) {
				this.hotToastService.error(
					this.transloco.translate('integrations.add_new.errors.same_integration', {
						integrationType: this.transloco.translate(
							'integrations.integrations.' + integration + '.name'
						),
					})
				);
			}

			throw err;
		};
	}

	public getGoogleDriveIntegrationUserData(
		code: string
	): Promise<GoogleDriveResponseAuthorizeUserInterface> {
		return this.http.post<GoogleDriveResponseAuthorizeUserInterface>(
			this.integrationsUrl('google-drive', 'authorize'),
			{ code }
		);
	}

	public createGoogleDriveIntegration(
		email: string,
		accessToken: string,
		refreshToken: string,
		driveId: string,
		driveName: string
	): Promise<{ integration: IntegrationInterface }> {
		return this.http.post<{ integration: IntegrationInterface }>(
			this.integrationsUrl('google-drive', 'register'),
			{ email, accessToken, refreshToken, driveId, driveName }
		);
	}

	public getOrRefreshGoogleDriveToken(integrationId: string): Promise<{ accessToken: string }> {
		const body = {
			integrationId,
		};
		return this.http.post<{ accessToken: string }>(
			this.integrationsUrl('google-drive', 'access-token'),
			body
		);
	}

	public getGoogleDriveSyncedIds(
		integrationId: string
	): Promise<GoogleDriveIntegrationFilesImportInterface> {
		const params = { integrationId };
		return this.http.get<GoogleDriveIntegrationFilesImportInterface>(
			this.integrationsUrl('google-drive', 'synced-ids'),
			params
		);
	}

	public sendFilesToGoogleDrive(
		integrationId: string,
		rootId: string,
		items: { fileId: string; folderPath?: { id: string; name: string }[] }[]
	): Promise<IntegrationExecutionInterface> {
		const body = {
			integrationId,
			files: items,
			rootId,
		};

		return this.http.post<IntegrationExecutionInterface>(
			this.integrationsUrl('google-drive', 'execute'),
			body
		);
	}

	public setGoogleDriveSyncedState(integrationId: string, isSync: boolean): Promise<void> {
		const body = { integrationId, isSync };
		return this.http.patch<void>(this.integrationsUrl('google-drive', 'synced-state'), body);
	}

	public syncGoogleDrive(integrationId: string): Promise<void> {
		const body = { integrationId };
		return this.http.post<void>(this.integrationsUrl('google-drive', 'sync'), body);
	}

	/**
	 * @deprecated Use `integrationsUrl` or other url method.
	 */
	protected url(...path: (string | number)[]): string {
		return super.url(...path);
	}
}
