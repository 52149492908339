export type DeepKeys<T> = T extends object
	? T extends Array<infer _>
		? never
		: {
				[K in keyof T]: [
					Exclude<K, symbol>,
					...(DeepKeys<T[K]> extends never ? [] : DeepKeys<T[K]>)
				];
		  }[keyof T]
	: never;

export type TypeFromDeepKeys<T, DK extends DeepKeys<T>> = T extends object
	? T extends Array<infer _>
		? T
		: {
				[KEY in keyof DK]: KEY extends keyof T ? T[KEY] : never;
		  }[keyof DK]
	: T;
