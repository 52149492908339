import { ChatNodesMessageAIAnswerTypeEnum, ChatNodesStrategyEnum } from '../chat-nodes';
import { QuotaTypeEnum, StandardQuotaStatusResponse } from '../quotas';
import { SearchParams } from '../search';
import { Nullable } from '../typescript';
import { ChatBotChatInterface, ChatBotChatOriginEnum } from './chat-bot-chat.type';
import {
	ChatBotMessageAIImpersonationEnum,
	ChatBotUserMessageInterface,
} from './chat-bot-message.type';

export type CreateChatRequest = {
	name: string;
	query: SearchParams;
	impersonation?: Nullable<ChatBotMessageAIImpersonationEnum>;
	answerType?: Nullable<ChatNodesMessageAIAnswerTypeEnum>;
	origin?: ChatBotChatOriginEnum;
	strategy?: ChatNodesStrategyEnum;
	useMemory?: boolean;
};

export type UpdateChatRequest = Partial<Omit<CreateChatRequest, 'origin'>>;

export type GetChatBotChatsRequest = {
	origins?: ChatBotChatOriginEnum[];
};

export type AskChatBotRequest = {
	question: string;
};

export type GetMessagesQuery = {
	messageIds: string[];
};

export type ChatEmbeddingIds = {
	embeddingIds: string[];
};

export type CreateChatBotChatResponse = {
	chat: ChatBotChatInterface;
	quota: Pick<StandardQuotaStatusResponse, QuotaTypeEnum.CHAT_BOT_CONVERSATIONS>;
};

export type UpdateChatBotChatResponse = {
	chat: ChatBotChatInterface;
};

export type AskChatBotResponse = {
	message: ChatBotUserMessageInterface;
	quota: Pick<StandardQuotaStatusResponse, QuotaTypeEnum.MONTHLY_CHAT_BOT_MESSAGES>;
};

export type GetChatBotMessagesResponse = {
	messages: ChatBotUserMessageInterface[];
	quota: Pick<StandardQuotaStatusResponse, QuotaTypeEnum.MONTHLY_CHAT_BOT_MESSAGES>;
};

export type EvaluateChatBotRequest = {
	responseId: string;
	rating: Nullable<number>; // set to null to remove
};
