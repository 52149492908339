import { CustomPropertyKeys } from '../custom-property';
import { NodeType } from '../nodes';
import { SearchAttribute, NodeFilterOperation } from '../search';

export type UuidString = string & { _t: 'UserPinnedSearchParam' };

export type EnumPinnedSearchParamTypes =
	| NodeType
	| SearchAttribute
	| NodeFilterOperation
	| CustomPropertyKeys;

export const enumPinnedSearchParamTypes = [
	NodeType,
	SearchAttribute,
	NodeFilterOperation,
	CustomPropertyKeys,
];

export type UserPinnedSearchParam = EnumPinnedSearchParamTypes | UuidString;
