import { NodeSubtypeSettingsInterface } from '../node-subtypes-settings';
import { NodeType } from '../nodes';

export enum NodeSubtypeEnum {
	ALBUM_BASE = 'ALBUM_BASE',
	FILE_ALBUM_ITEM = 'FILE_ALBUM_ITEM',
	FILE_BASE = 'FILE_BASE',
	FILE_IMAGE = 'FILE_IMAGE',
	FILE_VIDEO = 'FILE_VIDEO',
	FILE_AUDIO = 'FILE_AUDIO',
	NOTE_BASE = 'NOTE_BASE',
	TAG_COMPANY = 'TAG_COMPANY',
	TAG_CONTACT = 'TAG_CONTACT',
	TAG_PLACE = 'TAG_PLACE',
	TAG_TOPIC = 'TAG_TOPIC',
	WEBSITE_BASE = 'WEBSITE_BASE',
	WEBSITE_DOMAIN = 'WEBSITE_DOMAIN',
	WORKSPACE_BASE = 'WORKSPACE_BASE',
}

export interface NodeSubtypeInterface {
	// TODO: Maybe add enums also
	id: NodeSubtypeId;
	nodeType: NodeType;
}

export const ALLOWED_SUBTYPES_NODE_TYPES_SET = new Set([NodeType.TAG]);

export type CreateNodeSubtypeInterface = Pick<
	NodeSubtypeSettingsInterface,
	'icon' | 'name' | 'nodeType'
>;

export interface CreateNodeSubtypeResponse {
	nodeSubtype: NodeSubtypeInterface;
	nodeSubtypeSettings: NodeSubtypeSettingsInterface;
}

export type NodeSubtypeId = string | NodeSubtypeEnum;

export const nodeSubtypeToNodeType = {
	[NodeSubtypeEnum.ALBUM_BASE]: NodeType.ALBUM,
	[NodeSubtypeEnum.FILE_ALBUM_ITEM]: NodeType.FILE,
	[NodeSubtypeEnum.FILE_BASE]: NodeType.FILE,
	[NodeSubtypeEnum.FILE_IMAGE]: NodeType.FILE,
	[NodeSubtypeEnum.FILE_AUDIO]: NodeType.FILE,
	[NodeSubtypeEnum.FILE_VIDEO]: NodeType.FILE,
	[NodeSubtypeEnum.NOTE_BASE]: NodeType.NOTE,
	[NodeSubtypeEnum.TAG_COMPANY]: NodeType.TAG,
	[NodeSubtypeEnum.TAG_CONTACT]: NodeType.TAG,
	[NodeSubtypeEnum.TAG_PLACE]: NodeType.TAG,
	[NodeSubtypeEnum.TAG_TOPIC]: NodeType.TAG,
	[NodeSubtypeEnum.WEBSITE_BASE]: NodeType.WEBSITE,
	[NodeSubtypeEnum.WEBSITE_DOMAIN]: NodeType.WEBSITE,
	[NodeSubtypeEnum.WORKSPACE_BASE]: NodeType.WORKSPACE,
} as const satisfies { [n in NodeSubtypeEnum]: NodeType };

export const nodeTypeToNodeSubtype = Object.entries(nodeSubtypeToNodeType).reduce(
	(acc, [nodeSubtype, nodeType]) => {
		acc[nodeType] = acc[nodeType] ?? [];
		acc[nodeType].push(nodeSubtype as NodeSubtypeEnum);
		return acc;
	},
	{} as { [t in NodeType]: NodeSubtypeEnum[] }
);

export const tagNodesSubtypes = [
	NodeSubtypeEnum.TAG_COMPANY,
	NodeSubtypeEnum.TAG_CONTACT,
	NodeSubtypeEnum.TAG_PLACE,
	NodeSubtypeEnum.TAG_TOPIC,
] as const;

export const defaultNodeSubtypePerNodeType: { [type in NodeType]: NodeSubtypeEnum } = {
	[NodeType.TAG]: NodeSubtypeEnum.TAG_TOPIC,
	[NodeType.WORKSPACE]: NodeSubtypeEnum.WORKSPACE_BASE,
	[NodeType.WEBSITE]: NodeSubtypeEnum.WEBSITE_BASE,
	[NodeType.NOTE]: NodeSubtypeEnum.NOTE_BASE,
	[NodeType.ALBUM]: NodeSubtypeEnum.ALBUM_BASE,
	[NodeType.FILE]: NodeSubtypeEnum.FILE_BASE,
};
