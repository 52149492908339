import { CustomPropertyType } from '../../../custom-property/type.interface';
import { ExcludedPropertyTypes } from './excluded-property-types.type';
import { propertyTypeTextOverrideMap } from './property-type-text-override.map';

export const reversedPropertyTypeTextOverrideMap = Object.keys(propertyTypeTextOverrideMap).reduce(
	(acc, _key) => {
		const key = _key as Exclude<CustomPropertyType, ExcludedPropertyTypes>;
		const mapKey = propertyTypeTextOverrideMap[key] as string;
		acc[mapKey] = key;
		return acc;
	},
	{} as Record<string, Exclude<CustomPropertyType, ExcludedPropertyTypes>>
);
