export enum TableViewTypeEnum {
	ATTRIBUTE = 'ATTRIBUTE',
	CUSTOM_PROPERTY = 'CUSTOM_PROPERTY',
}

export enum TableViewNodeAttributeEnum {
	NODE_NAME = 'NODE_NAME',
	NODE_TYPE = 'NODE_TYPE',
	EMOJI = 'EMOJI',
	COMMENTS = 'COMMENTS',
	CREATED_AT = 'CREATED_AT',
	UPDATED_AT = 'UPDATED_AT',
}
