import { NodeType } from '../nodes';
import { SearchNodeResponse } from '../search';
import { Nullable, RecursiveRecord } from '../typescript';

export enum ChatAPIToolMethodEnum {
	GET = 'GET',
	POST = 'POST',
	PUT = 'PUT',
	PATCH = 'PATCH',
	DELETE = 'DELETE',
}

export enum ChatNodesToolEnum {
	SIMILARITY_SEARCH = 'SIMILARITY_SEARCH',
	NLP_SEARCH = 'NLP_SEARCH',
	CUSTOM_API_CALL = 'CUSTOM_API_CALL',
}

export enum DefaultChatAPIToolEnum {
	SHOPIFY_ORDER_RETRIEVAL = 'SHOPIFY_ORDER_RETRIEVAL',
}

export type ChatNodesSimilaritySearchToolInputType = {
	query: string;
	nodeIds: Nullable<Array<string>>;
};

export type ChatNodesCustomAPICallToolInputType = {
	params?: Record<string, unknown>;
	body?: Record<string, unknown>;
};

export type ChatNodesCustomAPICallToolOutputItemType = ChatNodesToolOutputItemBaseInterface & {
	item: unknown;
};

export type ChatNodesCustomAPICallToolOutputType =
	ChatNodesItemizedToolOutputBaseInterface<ChatNodesCustomAPICallToolOutputItemType> & {
		itemCount: number;
	};

export interface ChatNodesToolOutputItemBaseInterface {
	isUsed: boolean;
}

export interface ChatNodesItemizedToolOutputBaseInterface<
	T extends ChatNodesToolOutputItemBaseInterface
> {
	items: Array<T>;
}

export type ChatNodesSimilaritySearchToolOutputItemType = ChatNodesToolOutputItemBaseInterface & {
	embeddingId: string;
	text: string;
	node: SearchNodeResponse;
};

export type ChatNodesSimilaritySearchToolOutputType =
	ChatNodesItemizedToolOutputBaseInterface<ChatNodesSimilaritySearchToolOutputItemType>;

export type ChatNodesNLPSearchToolOutputItemType = ChatNodesToolOutputItemBaseInterface & {
	node: SearchNodeResponse;
};
export type ChatNodesNLPSearchToolOutputType =
	ChatNodesItemizedToolOutputBaseInterface<ChatNodesNLPSearchToolOutputItemType> & {
		nodeCount: number;
	};

export interface ChatNodesNLPSearchToolInputType {
	query?: string;
	nodeTypes?: Array<Exclude<NodeType, NodeType.WORKSPACE>>;
	textualPropertyFilters?: Array<{
		textualProperty: string;
		operator:
			| 'EQUALS'
			| 'NOT_EQUALS'
			| 'CONTAINS'
			| 'NOT_CONTAINS'
			| 'HAS_PROPERTY'
			| 'NOT_HAS_PROPERTY';
		value?: string;
	}>;
	datePropertyFilters?: Array<{
		dateProperty: string;
		dateRange: {
			start?: string;
			end?: string;
		};
	}>;
	numericPropertyFilters?: Array<{
		numericProperty: string;
		operator:
			| 'EQUALS'
			| 'NOT_EQUALS'
			| 'GREATER_THAN'
			| 'GREATER_THAN_OR_EQUAL'
			| 'LESS_THAN'
			| 'LESS_THAN_OR_EQUAL'
			| 'HAS_PROPERTY'
			| 'NOT_HAS_PROPERTY';
		value?: number;
	}>;
}

export type ChatNodesToolTypesMap = {
	[ChatNodesToolEnum.SIMILARITY_SEARCH]: {
		input: ChatNodesSimilaritySearchToolInputType;
		output: ChatNodesSimilaritySearchToolOutputType;
	};
	[ChatNodesToolEnum.NLP_SEARCH]: {
		input: ChatNodesNLPSearchToolInputType;
		output: ChatNodesNLPSearchToolOutputType;
	};
	[ChatNodesToolEnum.CUSTOM_API_CALL]: {
		input: ChatNodesCustomAPICallToolInputType;
		output: ChatNodesCustomAPICallToolOutputType;
	};
};

export interface ChatNodesToolUsageInterface<T extends ChatNodesToolEnum> {
	toolType: T;
	input: ChatNodesToolTypesMap[T]['input'];
	output: Nullable<ChatNodesToolTypesMap[T]['output']>;
	timestamp: number;
	customAPICallToolId: T extends ChatNodesToolEnum.CUSTOM_API_CALL ? string : null;
}

export interface BaseChatNodesCustomAPICallToolInterface {
	id: string;
	name: string;
	description: string;
	url: string;
	method: string;
	headers: Record<string, string>;
	itemsPath?: string;
	itemIdPath?: string;
	itemCountPath?: string;
	itemFieldsPaths?: RecursiveRecord<string, string>;
	default: DefaultChatAPIToolEnum | undefined;
	schema: Record<string, unknown>;
	associatedToolIds: Array<string>;
}
export type ChatNodesCustomAPICallToolInterface = BaseChatNodesCustomAPICallToolInterface &
	(
		| { default: undefined }
		| {
				default: DefaultChatAPIToolEnum.SHOPIFY_ORDER_RETRIEVAL;
				shopName: string;
				accessToken: string;
		  }
	);

export interface CreateShopifyOrderRetrievalDefaultChatAPIToolRequest {
	accessToken: string;
	shopName: string;
}

// Aquest tipus es bastant merda, hauria de ser generic en funcio de l'enum i fer-lo servir pel body.
export interface CreateDefaultChatAPIToolRequest {
	default: DefaultChatAPIToolEnum;
	shopifyOrderRetrievalBody?: CreateShopifyOrderRetrievalDefaultChatAPIToolRequest;
	userPrivateId?: string;
}

export interface UpdateChatAPIToolRequest {
	description?: string;
	url?: string;
	method?: ChatAPIToolMethodEnum;
	headers?: Record<string, string>;
	itemsPath?: string;
	itemIdPath?: string;
	itemCountPath?: string;
	itemFieldsPaths?: RecursiveRecord<string, string>;
	schema?: Record<string, unknown>;
}
