import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { SignInWithAppleResponse } from '@capacitor-community/apple-sign-in';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CompleteRegistrationBody, Language, Nullable, UserFeatureFlags } from '@reach/interfaces';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthApiService, DiscourseApiService } from '~app-client/api/services';
import { ClientUser } from '~app-client/core/types';
import { ReachStorage } from '~app-client/core/utils';
import { AnalyticsEvent, AnalyticsService } from '../analytics';
import { FirebaseDeviceService } from '../firebase-device.service';
import { AdTrackEvent, AdTrackingService } from '../pixel/ad-tracking.service';

/**
 * Service that handles all the logic regarding Session.
 */
@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class SessionService {
	private readonly firebaseDeviceService = inject(FirebaseDeviceService);

	private user$$ = new BehaviorSubject<Nullable<ClientUser>>(null);
	public user$ = this.user$$.asObservable();

	private loggedOut$$ = new Subject<void>();
	public loggedOut$ = this.loggedOut$$.asObservable();

	private loading$$ = new BehaviorSubject<boolean>(false);
	public loading$ = this.loading$$.asObservable();

	private currentUser?: Partial<ClientUser>;

	public untilLoadingFinished(): Promise<void> {
		return new Promise<void>((resolve) => {
			const stop$ = new Subject<void>();
			this.loading$.pipe(takeUntil(stop$)).subscribe((loading) => {
				if (loading === false) {
					stop$.next();
					resolve();
				}
			});
		});
	}

	private _triedToLogin = false;
	public get triedToLogin(): boolean {
		return this._triedToLogin;
	}

	private lastUserId: string | null = null;

	private readonly analytics = inject(AnalyticsService);
	private readonly authApi = inject(AuthApiService);
	private readonly discourseApi = inject(DiscourseApiService);
	private readonly adTrackingService = inject(AdTrackingService);

	constructor() {
		this.user$.pipe(untilDestroyed(this)).subscribe(async (user) => {
			await this.handleUserChange(user);
		});
	}

	public hasFeatureFlag(flag: UserFeatureFlags): boolean {
		return (this.user?.featureFlags || []).includes(flag);
	}

	public addData(data: Partial<ClientUser>): void {
		this.currentUser = {
			...(this.currentUser || {}),
			...data,
		};

		this.emitChanges();
	}

	public get user(): null | ClientUser {
		return this.currentUser as ClientUser;
	}

	public async ping(): Promise<void> {
		try {
			this.loading$$.next(true);
			this._triedToLogin = true;

			const user = await this.authApi.ping();

			this.currentUser = {
				...(this.currentUser || {}),
				...user,
				unclaimedAchievementsCount: this.currentUser?.unclaimedAchievementsCount || 0,
			};

			this.emitChanges();
			await this.checkSpecialActions();
		} catch (e) {
			if ((this.currentUser || null) !== null) {
				await this.logout();
			}
			this.handleRequestError(e);
		} finally {
			this.loading$$.next(false);
		}
	}

	public async login(usernameOrEmail: string, password: string): Promise<void> {
		try {
			this.loading$$.next(true);
			this._triedToLogin = true;
			const user = await this.authApi.login(usernameOrEmail, password);
			this.currentUser = {
				...(this.currentUser || {}),
				...user,
				unclaimedAchievementsCount: this.currentUser?.unclaimedAchievementsCount || 0,
			};
			this.emitChanges();
			await this.checkSpecialActions();
		} catch (e) {
			this.currentUser = undefined;
			this.emitChanges();
			this.handleRequestError(e);
			throw e;
		} finally {
			this.loading$$.next(false);
		}
	}

	public async register(
		username: string,
		email: string,
		password: string,
		language: Language,
		referralId?: string
	): Promise<void> {
		const user = await this.authApi.register({
			user: {
				username,
				email,
				password,
				language,
			},
			referralId,
		});
		this.currentUser = {
			...(this.currentUser || {}),
			...user,
			unclaimedAchievementsCount: this.currentUser?.unclaimedAchievementsCount || 0,
		};
		this.emitChanges();
		await this.checkSpecialActions();
	}

	public async completeRegistration(data: CompleteRegistrationBody): Promise<void> {
		const user = await this.authApi.completeRegistration(data);
		this.currentUser = user;
		this.analytics.addEvent(AnalyticsEvent.COMPLETE_REGISTRATION_SUCCESS, {});
		this.adTrackingService.track(AdTrackEvent.COMPLETE_REGISTRATION, {});
		this.emitChanges();
	}

	public async googleSignIn(
		authToken: string,
		language: Language,
		referralId?: string
	): Promise<void> {
		try {
			await this.socialSignInHandler(() => {
				return this.authApi.signInWithGoogle(authToken, language, referralId);
			});
		} catch (error) {}
	}

	public async appleSignIn(
		appleResponse: SignInWithAppleResponse,
		language: Language,
		referralId?: string
	): Promise<void> {
		try {
			await this.socialSignInHandler(() => {
				return this.authApi.signInWithApple(appleResponse, language, referralId);
			});
		} catch (error) {}
	}

	/**
	 * TODO: To logout even with the api down, we can set a value in the storage
	 * saying that we should not ping.
	 */
	public async logout(): Promise<void> {
		try {
			await this.firebaseDeviceService.removeDevice();
			await this.authApi.logout();
			this._triedToLogin = false;
		} catch (e) {}
		this.currentUser = undefined;
		this.emitChanges();
		this.loggedOut$$.next();
		ReachStorage.clear();
	}

	private handleRequestError(e: unknown): void {
		if (e instanceof HttpErrorResponse) {
			return this.handleRequestHttpError(e);
		}

		return this.handleUnknownError(e);
	}

	private handleRequestHttpError(e: HttpErrorResponse): void {
		const status = e.status;
		switch (status) {
			case 403:
				return this.handleUnauthorizedError(e);
		}
		throw e;
	}

	/**
	 * TODO: Do we really need this?
	 */
	private handleUnauthorizedError(e: HttpErrorResponse): void {
		console.warn('Check if this is needed');
		throw e;
	}

	private handleUnknownError(e: unknown): void {
		console.error(e);
		throw e;
	}

	private async handleUserChange(user: ClientUser | null): Promise<void> {
		const userId = user?.id || null;

		if (!!this.lastUserId && !userId) {
			this.analytics.reset();
		}

		if (this.lastUserId !== userId) {
			try {
				this.analytics.setUser(userId);

				if (!!user) {
					this.firebaseDeviceService.addDevice();
					this.analytics.addUserData(user);
				}
			} catch (error) {
				console.error(error)
			}
		}

		this.lastUserId = userId;
	}

	private emitChanges(): void {
		this.user$$.next(this.currentUser as ClientUser);
	}

	private async socialSignInHandler(socialLogin: () => Promise<unknown>): Promise<void> {
		try {
			await socialLogin();

			try {
				await this.ping();
				await this.checkSpecialActions();
			} catch (_) {}
		} catch (error) {
			this.currentUser = undefined;
			this.emitChanges();
			this.handleRequestError(error);
		}
	}

	private redirectIfNeeded(): void {
		try {
			const url = new URL(location as unknown as string);
			const redirectUrl = decodeURIComponent(
				`${url.searchParams.get('redirectUrl') || ''}`.trim()
			);
			if (redirectUrl !== '') {
				if (Capacitor.isNativePlatform()) {
					Browser.open({ url: redirectUrl });
				} else {
					location = redirectUrl as unknown as Location;
				}
			}
		} catch (error) {}
	}

	private async checkSpecialActions(): Promise<void> {
		try {
			this.redirectIfNeeded();

			const url = new URL(location as unknown as string);
			const specialAction = decodeURIComponent(
				`${url.searchParams.get('specialAction') || ''}`.trim()
			);

			switch (specialAction) {
				case 'discourseLogin':
					await this.handleDiscourseLogin();
					break;
			}
		} catch (error) {}
	}

	private async handleDiscourseLogin(): Promise<void> {
		const url = new URL(location as unknown as string);
		const ssoKey = decodeURIComponent(`${url.searchParams.get('sso') || ''}`.trim());
		const signatureKey = decodeURIComponent(`${url.searchParams.get('sig') || ''}`.trim());

		const response = await this.discourseApi.getRedirectUrl(ssoKey, signatureKey);

		if (response.redirectUrl) {
			if (Capacitor.isNativePlatform()) {
				Browser.open({ url: response.redirectUrl });
			} else {
				location = response.redirectUrl as unknown as Location;
			}
		}
	}
}
