import { TableViewInterface } from './table-view.interface';

export type GetTableViewsResponseType = {
	tableViews: TableViewInterface[];
};

export type CreateTableViewResponseType = {
	tableView: TableViewInterface;
};

export type UpdateTableViewResponseType = CreateTableViewResponseType;
