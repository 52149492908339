import { Injectable } from '@angular/core';
import {
	CustomPropertyCreateRequest,
	CustomPropertyInterface,
	CustomPropertyGetInterface,
	CustomPropertyType,
	CustomPropertyUpdateVisibilityInterface,
} from '@reach/interfaces';
import { BaseApiService } from '~app-client/api/_internal';
import { ReachNodeId } from '~app-client/core/types';

@Injectable({ providedIn: 'root' })
export class PropertiesApiService extends BaseApiService {
	protected commonPath = ['users', 'me', 'custom-properties'];

	public createCustomProperty(
		propertyName: string,
		propertyType: CustomPropertyType
	): Promise<CustomPropertyInterface> {
		const body = {
			name: propertyName,
			type: propertyType,
		};

		return this.http.post<CustomPropertyInterface>(this.url(), body).then((response) => {
			return Array.isArray(response) ? response[0] : response;
		});
	}

	public updatePropertyOptions(
		propertyId: string,
		options: string[]
	): Promise<CustomPropertyInterface> {
		const body = {
			options: options
				.map((option) => {
					return `${option || ''}`.trim();
				})
				.filter((option) => {
					return !!option;
				}),
		};
		return this.http.post<CustomPropertyInterface>(this.url(propertyId, 'options'), body);
	}

	/**
	 * @deprecated Use `upsertPropertyToNode` instead.
	 */
	public addPropertyToNode(
		nodeIds: ReachNodeId[],
		customProperty: CustomPropertyCreateRequest
	): Promise<void> {
		const body = { nodeIds, ...customProperty };
		return this.http.post<void>(this.url('upsert-value'), body);
	}

	public upsertPropertyToNode(
		nodeIds: ReachNodeId[],
		customProperty: CustomPropertyCreateRequest
	): Promise<void> {
		const body = { nodeIds, ...customProperty };
		return this.http.post<void>(this.url('upsert-value'), body);
	}

	public updateVisibilityValueToNode(
		nodeIds: ReachNodeId[],
		customProperty: CustomPropertyUpdateVisibilityInterface
	): Promise<void> {
		const body = { nodeIds, ...customProperty };
		return this.http.post<void>(this.url('visibility'), body);
	}

	public removePropertyFromNode(nodeIds: ReachNodeId[], customPropertyId: string): Promise<void> {
		const body = { nodeIds, customPropertyId };
		return this.http.post<void>(this.url('remove'), body);
	}

	public getUserProperties(): Promise<CustomPropertyInterface[]> {
		return this.http.post<CustomPropertyInterface[]>(this.url('get-custom-properties'));
	}

	public getWorkspacesUserProperties(
		workspaceIds: ReachNodeId[],
		includeUnassigned: boolean
	): Promise<CustomPropertyInterface[]> {
		const body: CustomPropertyGetInterface = {
			workspaceIds: workspaceIds,
			unassigned: includeUnassigned,
		};
		return this.http.post<CustomPropertyInterface[]>(this.url('get-custom-properties'), body);
	}

	public deleteProperty(propertyId: string): Promise<void> {
		return this.http.delete<void>(this.url(propertyId));
	}

	public renameProperty(propertyId: string, name: string): Promise<void> {
		const body = { name };
		return this.http.post<void>(this.url(propertyId, 'name', 'all'), body);
	}
}
