import {
	ChatNodesCustomAPICallToolInterface,
	ChatNodesMessageAIAnswerTypeEnum,
	ChatNodesStrategyEnum,
} from '../chat-nodes';
import { SearchParams } from '../search';
import { Nullable } from '../typescript';
import { ChatBotMessageAIImpersonationEnum } from './chat-bot-message.type';

export interface ChatBotChatInterface {
	id: string;
	name: string;
	query: Required<SearchParams>;
	impersonation: Nullable<ChatBotMessageAIImpersonationEnum>;
	answerType: Nullable<ChatNodesMessageAIAnswerTypeEnum>;
	origin: ChatBotChatOriginEnum;
	strategy: ChatNodesStrategyEnum;
	useMemory: boolean;
	createdAt: number;
	updatedAt: number;
	lastUsedAt: number;
	customAPICallTools: Array<ChatNodesCustomAPICallToolInterface>;
}

export enum ChatBotChatOriginEnum {
	DEFAULT = 'DEFAULT',
	SEARCH = 'SEARCH',
	NODE = 'NODE',
}
