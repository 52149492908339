import { ChatNodesCustomAPICallToolInterface } from '../chat-nodes';
import { SearchParams } from '../search';
import { Nullable } from '../typescript';
import { ChatWidgetIconSizeEnum } from './chat-widget-icon-size.enum';

export enum ChatWidgetPageLocationEnum {
	BOTTOM_RIGHT = 'BOTTOM_RIGHT',
	BOTTOM_LEFT = 'BOTTOM_LEFT',
}

export interface ChatWidgetCategoryInterface {
	name: string;
	description: string;
	colour: number[];
}

export interface ChatWidgetUtmInterface {
	utmSource?: Nullable<string>;
	utmMedium?: Nullable<string>;
	utmCampaign?: Nullable<string>;
	utmTerm?: Nullable<string>;
	utmContent: boolean;
}
export interface ChatWidgetInterface {
	id: string;
	createdAt: number;
	updatedAt: number;
	lastUsedAt: Nullable<number>;
	settings: ChatWidgetSettingsInterface;
	customization: ChatWidgetCustomizationInterface;
}

export interface ChatWidgetSettingsInterface {
	name: string;
	active: boolean;
	query: Required<SearchParams>;
	showWebsites: boolean;
	showFiles: boolean;
	contactEmail: string;
	whitelist: string[];
	categories: ChatWidgetCategoryInterface[];
	utm: ChatWidgetUtmInterface;
	customAPICallTools: Array<ChatNodesCustomAPICallToolInterface>;
}

export interface ChatWidgetCustomizationInterface {
	logoImageUrl: Nullable<string>;
	logoThumbnailUrl: Nullable<string>;
	iconImageUrl: Nullable<string>;
	iconThumbnailUrl: Nullable<string>;
	assistantName?: Nullable<string>;
	title?: Nullable<string>;
	welcomeMessage: string[];
	colours: ChatWidgetCustomizationColoursInterface[];
	defaultQuestions: string[];
	inputPlaceholder?: Nullable<string>;
	popUpMessage?: Nullable<string>;
	errorMessage?: Nullable<string>;
	pageLocation?: ChatWidgetPageLocationEnum;
	clearText?: Nullable<string>;
	contactText?: Nullable<string>;
	sendText?: Nullable<string>;
	popupMessageDisabled: boolean;
	iconSize: ChatWidgetIconSizeEnum;
}

export interface ChatWidgetCustomizationColoursInterface {
	name: string;
	primary: Nullable<number[]>;
	hover: Nullable<number[]>;
	mainText: Nullable<number[]>;
	secondaryText: Nullable<number[]>;
	background: Nullable<number[]>;
	secondaryBackground: Nullable<number[]>;
	logoBackground: Nullable<number[]>;
	line: Nullable<number[]>;
	iconColour: Nullable<number[]>;
	error: Nullable<number[]>;
}
