import { Nullable } from '../typescript';
import { DeepOmit, DeepPartial } from 'ts-essentials';
import { StandardQuotaStatusResponse, QuotaTypeEnum } from '../quotas';
import { ChatWidgetInterface } from './chat-widget.types';
import { ChatWidgetUserMessageInterface } from './chat-widget-message.types';
import { ImageUrl } from '../image';
import { ChatWidgetSessionUsageInterface } from './chat-widget-usage.types';
import { PipelineInterface } from '../pipelines';

// Create Widget
export interface CreateChatWidgetRequest {
	name: string;
	contactEmail: string;
}

export type CreateChatWidgetResponse = {
	widget: ChatWidgetInterface;
	quota: Pick<StandardQuotaStatusResponse, QuotaTypeEnum.CHAT_WIDGETS>;
};

// Get Widgets
export type GetChatWidgetsResponse = ChatWidgetInterface[];

// Get Widget
export interface GetChatWidgetResponse {
	widget: ChatWidgetInterface;
	isSearchValid: boolean;
}

// Update Widget
export type UpdateChatWidgetRequest = DeepPartial<
	DeepOmit<
		ChatWidgetInterface,
		{
			id: true;
			createdAt: true;
			updatedAt: true;
			lastUsedAt: true;
			userPrivateId: true;
			customization: {
				logoImageUrl: true;
				logoThumbnailUrl: true;
				iconImageUrl: true;
				iconThumbnailUrl: true;
			};
		}
	>
>;
export type UpdateChatWidgetResponse = ChatWidgetInterface;

// Set Widget Logo
export type SetLogoChatWidgetResponse = ImageUrl;

export type SetIconChatWidgetResponse = ImageUrl;

// Delete Widget
export type DeleteChatWidgetResponse = void;

// Get Widget Chat Messages Page

export type GetChatWidgetMessagesResponse = {
	messages: ChatWidgetUserMessageInterface[];
	quota: Pick<StandardQuotaStatusResponse, QuotaTypeEnum.MONTHLY_CHAT_WIDGET_MESSAGES>;
};

// Get Widget Chat Messages By Ids
export type GetChatWidgetMessagesQuery = {
	messageIds: string[];
};

export type GetChatWidgetMessagesByIdsResponse = {
	messages: ChatWidgetUserMessageInterface[];
	quota: Pick<StandardQuotaStatusResponse, QuotaTypeEnum.MONTHLY_CHAT_WIDGET_MESSAGES>;
};

// Ask Chat Widget
export type AskChatWidgetRequest = {
	question: string;
	originUrl?: Nullable<string>;
};

export type AskChatWidgetResponse = {
	message: ChatWidgetUserMessageInterface;
	quota: Pick<StandardQuotaStatusResponse, QuotaTypeEnum.MONTHLY_CHAT_WIDGET_MESSAGES>;
};

// Set Reaction To Chat Widget
export type ChatWidgetSetReactionRequest = {
	responseId: string;
	rating?: Nullable<number>; // set to null to remove
	hasContactedSupport?: boolean;
	sourceOpened?: string;
};
export type ChatWidgetSetReactionResponse = void;

// Delete Chat Widget
export type DeleteChatWidgetChatResponse = void;

// Delete Chat Widget Chat History
export type DeleteChatWidgetChatHistoryResponse = void;

// List Session Usages
export type ListChatWidgetSessionUsageResponse = {
	sessionUsages: ChatWidgetSessionUsageInterface[];
	count: number;
};

export type ChatWidgetSessionUsageResponse = {
	usage: ChatWidgetSessionUsageInterface;
};

// Get List Message
export type ListChatWidgetMessagesResponse = {
	messages: ChatWidgetUserMessageInterface[];
	count: number;
};

// Get List Message Pages
export type GetChatWidgetListMessagesResponse = GetChatWidgetMessagesResponse;

// Export Widget Data
export interface ExportChatWidgetDataRequest {
	timeZone: string;
}
export interface ExportChatWidgetDataResponse {
	pipeline: PipelineInterface;
}
