import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CLIENT_ENVIRONMENT } from '~app-client/core/tokens';
import { ClientEnvironmentName } from '~app-client/core/types';

const ANOTHER_URL_TO_REPLACE = '';

@Injectable({ providedIn: 'root' })
export class ReplaceBackendUrlInterceptor implements HttpInterceptor {
	private readonly environment = inject(CLIENT_ENVIRONMENT);

	intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		if (!req.url.startsWith(this.environment.apiBaseUrl)) {
			return next.handle(req);
		}

		if (this.environment.environmentName === ClientEnvironmentName.LOCAL) {
			return next.handle(req).pipe(
				tap((event) => {
					if (event instanceof HttpResponse) {
						let rootUrl = this.environment.apiBaseUrl;
						if (rootUrl[rootUrl.length - 1] === '/') {
							rootUrl = rootUrl.slice(0, -1);
						}
						iter(event.body, rootUrl);
					}
				})
			);
		} else {
			return next.handle(req);
		}
	}
}

function iter<T>(o: T, strToReplace: string): void {
	Object.keys(o || {}).forEach(function (k) {
		if (o[k] !== null && typeof o[k] === 'object') {
			iter(o[k], strToReplace);
			return;
		}
		if (typeof o[k] === 'string') {
			if (ANOTHER_URL_TO_REPLACE) {
				o[k] = o[k].replace(ANOTHER_URL_TO_REPLACE, strToReplace);
			}
			o[k] = o[k].replace('http://localhost:3000', strToReplace);
		}
	});
}
