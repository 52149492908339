import { EventBaseInterface } from '../event-base.interface';
import { Nullable } from '../../typescript';

export interface ChatWidgetEventInterface extends EventBaseInterface {
	widgetId: string;
	sessionId: string;
	city: Nullable<string>;
	country: Nullable<string>;
}

export type CreateChatWidgetEventInterface = Omit<ChatWidgetEventInterface, 'id' | 'timestamp'>;

export type ChatWidgetEventRequestDateInterval = {
	from: number;
	to: number;
};
export type PartialChatWidgetEventRequestDateInterval = Pick<
	ChatWidgetEventRequestDateInterval,
	'from'
>;
export type ChatWidgetEventDateInterval = {
	from: Date;
	to: Date;
};

export type ChatWidgetEventStatisticsCountry = {
	country: string | null;
	cities: ChatWidgetEventStatisticsCity[];
};
export type ChatWidgetEventStatisticsCity = {
	city: string | null;
	count: number;
};
