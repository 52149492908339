import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaderResponse, HttpInterceptor, HttpProgressEvent, HttpRequest, HttpResponse, HttpSentEvent, HttpStatusCode, HttpUserEvent } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { CLIENT_METADATA_VERSION } from '@reach/interfaces';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CLIENT_ENVIRONMENT, REACH_VERSION } from '~app-client/core/tokens';
import { VersionErrorService } from '~app-client/version-error/services';

@Injectable({ providedIn: 'root' })
export class VersionErrorInterceptor implements HttpInterceptor {
	private readonly version = inject(REACH_VERSION);
	private readonly versionErrorService = inject(VersionErrorService);
	private readonly environment = inject(CLIENT_ENVIRONMENT);

	intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		if (!req.url.startsWith(this.environment.apiBaseUrl)) {
			return next.handle(req);
		}

		const reqWithVersion = req.clone({
			headers: req.headers.set(CLIENT_METADATA_VERSION, this.version),
		});

		return next.handle(reqWithVersion).pipe(
			tap({
				next: (event) => {
					this.handleEvent(event);
				},
				error: (event) => {
					this.handleEvent(event);
				},
			})
		);
	}

	private handleEvent(
		event:
			| HttpSentEvent
			| HttpHeaderResponse
			| HttpProgressEvent
			| HttpResponse<unknown>
			| HttpUserEvent<unknown>
	): void {
		if (
			event instanceof HttpErrorResponse &&
			event.status === HttpStatusCode.ExpectationFailed
		) {
			this.versionErrorService.navigateToPage();
		}
	}
}
