import { TableViewTypeEnum } from './table-view-type.enum';

export interface TableViewInterface {
	id: string;
	name?: string;
	description?: string;
	emoji?: string;
	columns: TableViewColumnInterface[];
}

export interface TableViewColumnInterface {
	columnId: string;
	type: TableViewTypeEnum;
}

export interface TableViewIdInterface {
	tableViewId: string;
}

export type TableViewContentInterface = Omit<TableViewInterface, 'id'>;

export type TableViewGetResponse = TableViewInterface[];

export type TableViewCreateBody = TableViewContentInterface;
export type TableViewCreateResponse = TableViewInterface;

export type TableViewUpdateBody = TableViewContentInterface;

export type TableViewUpdateResponse = TableViewInterface;
