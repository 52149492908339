import { inject, Injectable } from '@angular/core';
import { User as SentryUser } from '@sentry/browser';
import { ClientService } from '../client/client.service';
import { MixpanelService } from '../mixpanel/mixpanel.service';
import { SentryService } from '../sentry.service';
import { AnalyticsEvent } from './types/keys';
import { AnalyticsEventData } from './types/map';

@Injectable({ providedIn: 'root' })
export class AnalyticsService {
	private readonly clientMetadata = inject(ClientService);
	private readonly mixpanel = inject(MixpanelService);
	private readonly sentry = inject(SentryService);

	public init(): void {
		this.mixpanel.init();

		this.setGlobalEventProperties();
	}

	public setGlobalEventProperties(): void {
		this.mixpanel.ifValid(() => {
			this.mixpanel.mixpanel.register({
				os: this.clientMetadata.os,
				native: this.clientMetadata.isNative,
				client: 'mobile',
			});
		});
	}

	public async setUser(id: string | null): Promise<void> {
		this.sentry.getCurrentScope().setUser({ id } as SentryUser);

		if (id) {
			this.mixpanel.identify?.(id);
			this.addEvent(AnalyticsEvent.LOGIN, {});
		}
	}

	public async reset(): Promise<void> {
		await this.addEvent(AnalyticsEvent.LOGOUT, {});
		this.mixpanel.reset();
		this.setGlobalEventProperties();
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public addUserData(data: { [key: string]: any }): void {
		this.sentry.getCurrentScope().setExtras(data);
		this.mixpanel.addItems(data);
	}

	public addEvent<KEY extends AnalyticsEvent>(
		eventName: KEY,
		eventData: KEY extends keyof AnalyticsEventData
			? AnalyticsEventData[KEY]
			: Record<string, never>
	): Promise<void> {
		this.sentry.addBreadcrumb({ category: eventName, data: eventData });
		return this.mixpanel.track(eventName, eventData);
	}

	public addException(description: string, fatal = false): Promise<void> {
		this.sentry.captureException(description, { fatal });
		return this.mixpanel.track('EXCEPTION', { description, fatal });
	}
}
