import { Nullable } from '../typescript';

export type ChatWidgetSessionUsageInterface = {
	id: string;
	widgetId: string;
	sessionId: string;
	createdAt: number;
	lastAccess: number;
	lastAsked: Nullable<number>;
	location: Nullable<string>;
	messagesCount: number;
	unreadMessagesCount: number;
	upvotesCount: number;
	unreadUpvotesCount: number;
	downvotesCount: number;
	unreadDownvotesCount: number;
	contactedSupportCount: number;
	unreadContactedSupportCount: number;
	categories: string[];
};
