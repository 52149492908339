import { AppError } from './app-error';
import { StatusCodes } from 'http-status-codes';
import { ErrorCodes } from '@reach/interfaces';
import { AppErrorParams } from './app-error-params.type';

export class InternalServerError extends AppError {
	constructor({ error }: { error: Error }) {
		super({
			status: StatusCodes.INTERNAL_SERVER_ERROR,
			code: ErrorCodes.MISC_INTERNAL_SERVER_ERROR,
			title: 'Internal Server Error.',
		});
		this.stack = error.stack;
		this.message = error.message;
	}

	public override toPayload(): AppErrorParams {
		return super.toPayload();
	}
}
