import { ChatWidgetEventTimeUnitEnum } from './chat-widget-event-time-unit.enum';
import { ChatWidgetEventEnum } from './chat-widget-event.enum';
import {
	ChatWidgetEventRequestDateInterval,
	ChatWidgetEventStatisticsCountry,
} from './chat-widget-event.interface';
export type ChatWidgetEventStatisticsEventsRequest = {
	widgetId: string;
	intervals: ChatWidgetEventRequestDateInterval[];
};
export type ChatWidgetEventStatisticsPayloadsRequest = ChatWidgetEventStatisticsEventsRequest;
export type ChatWidgetEventStatisticsLocationsRequest = {
	widgetId: string;
	intervals: ChatWidgetEventRequestDateInterval[];
};

export type ChatWidgetWithoutCategoryEvents = Exclude<ChatWidgetEventEnum, 'CATEGORY'>;
export type ChatWidgetEventStatisticsSessionsRequest = {
	widgetId: string;
	intervals: ChatWidgetEventRequestDateInterval[];
	timeUnit: ChatWidgetEventTimeUnitEnum;
	timeZone: string;
};

export type ChatWidgetEventStatisticsPayloadsResponse = {
	urls: { url: string; count: number }[][];
	openedNodeIds: { nodeId: string; count: number }[][];
	usedNodeIds: { nodeId: string; count: number }[][];
};

export type ChatWidgetEventStatisticsEventsResponse = {
	events: { [type in ChatWidgetWithoutCategoryEvents]: number }[];
	categories: { category: string; count: number }[][];
};

export type ChatWidgetEventStatisticsLocationsResponse = {
	locations: {
		interval: ChatWidgetEventRequestDateInterval;
		countries: ChatWidgetEventStatisticsCountry[];
	}[];
};

export type ChatWidgetEventStatisticsSessionsResponse = {
	totalWidgetSessions: {
		timestamp: number;
		sessions: number;
	}[][];
};
