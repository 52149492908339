import { NodeType, SearchParams } from '@reach/interfaces';
import { ensureAssert } from '../assertions';

export function isChatWidgetSearchValid(searchParams: Required<SearchParams>): boolean {
	const nodeTypesSet = new Set(searchParams.nodeTypes);

	const hasNoQuery = !searchParams.query;
	const hasNoFilters =
		searchParams.propertyFilters.length === 0 &&
		searchParams.attributeFilters.length === 0 &&
		searchParams.nodesFilters.length === 0;
	const isNotFuzzy = !searchParams.fuzzy;
	const isNotAndOr = searchParams.andOr === 'and';
	const hasWorkspaceIds =
		Array.isArray(searchParams.workspaceIds) && searchParams.workspaceIds.length > 0;
	const doesNotIncludeUnassigned = !searchParams.unassigned;
	const hasAllNodeTypes =
		nodeTypesSet.has(NodeType.ALBUM) &&
		nodeTypesSet.has(NodeType.FILE) &&
		nodeTypesSet.has(NodeType.NOTE) &&
		nodeTypesSet.has(NodeType.TAG) &&
		nodeTypesSet.has(NodeType.WEBSITE);

	return [
		hasNoQuery,
		hasNoFilters,
		isNotFuzzy,
		isNotAndOr,
		hasWorkspaceIds,
		doesNotIncludeUnassigned,
		hasAllNodeTypes,
	].every((v) => v);
}

export function assertChatWidgetSearchValid<A>(
	searchParams: Required<SearchParams>,
	errorFn?: (...args: A[]) => never,
	...errorArgs: A[]
): void {
	if (isChatWidgetSearchValid(searchParams)) {
		return;
	}

	if (ensureAssert(errorFn)) {
		errorFn(...errorArgs);
	}

	throw new Error(
		`Invalid search params for chat widget search.\n\nSearchParams: ${JSON.stringify(
			searchParams
		)}`
	);
}
