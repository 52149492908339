export enum QuotaTypeEnum {
	NODES = 'NODES',
	STORAGE = 'STORAGE',
	CHAT_BOT_CONVERSATIONS = 'CHAT_BOT_CONVERSATIONS',
	MONTHLY_CHAT_BOT_MESSAGES = 'MONTHLY_CHAT_BOT_MESSAGES',
	FILE_UPLOAD_LIMIT_IN_BYTES = 'FILE_UPLOAD_LIMIT_IN_BYTES',
	CHAT_WIDGETS = 'CHAT_WIDGETS',
	MONTHLY_CHAT_WIDGET_MESSAGES = 'MONTHLY_CHAT_WIDGET_MESSAGES',
	INTEGRATION_NODES = 'INTEGRATION_NODES',
}

export type StandardQuotaTypeEnum = Exclude<QuotaTypeEnum, typeof QuotaTypeEnum.INTEGRATION_NODES>;
