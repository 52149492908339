import { Injectable } from '@angular/core';
import {
	AskChatBotResponse,
	ChatBotChatInterface,
	ChatBotChatOriginEnum,
	ChatBotUserMessageInterface,
	CreateChatBotChatResponse,
	CreateChatRequest,
	EvaluateChatBotRequest,
	GetChatBotMessagesResponse,
	OrderByEnum,
	RequestPaginationInterface,
	UpdateChatRequest,
} from '@reach/interfaces';
import { BaseApiService } from '~app-client/api/_internal';

@Injectable({ providedIn: 'root' })
export class ChatAiApiService extends BaseApiService {
	protected commonPath = ['chat-bot'];

	public getMessagesByQuestionIds(
		chatId: string,
		questionIds: string[]
	): Promise<GetChatBotMessagesResponse> {
		const params = {
			messageIds: questionIds,
			t: this.paramTime,
		};
		return this.http.get<GetChatBotMessagesResponse>(this.url(chatId, 'by-ids'), params);
	}

	public ask(chatId: string, question: string): Promise<AskChatBotResponse> {
		const body = {
			question,
		};
		this.cleanCache();
		return this.http.post<AskChatBotResponse>(this.url(chatId, 'ask'), body);
	}

	public load(
		chatId: string,
		options: Required<
			Pick<RequestPaginationInterface<ChatBotUserMessageInterface, 'timestamp'>, 'size'>
		> &
			Pick<RequestPaginationInterface<ChatBotUserMessageInterface, 'timestamp'>, 'cursor'>
	): Promise<GetChatBotMessagesResponse> {
		const params = {
			size: `${options.size}`,
			cursor: `${options.cursor}`,
			orderBy: OrderByEnum.DESC,
			t: `${this.cacheTimer}`,
		};
		return this.http.get<GetChatBotMessagesResponse>(this.url(chatId, 'messages'), params);
	}

	public clearChat(chatId: string): Promise<void> {
		this.cleanCache();
		return this.http.delete<void>(this.url(chatId, 'history'));
	}

	public deleteChat(chatId: string): Promise<void> {
		this.cleanCache();
		return this.http.delete<void>(this.url(chatId, '/'));
	}

	public likeAnswer(chatId: string, answerId: string): Promise<void> {
		const body: EvaluateChatBotRequest = {
			rating: 1,
			responseId: answerId,
		};
		return this.http.post<void>(this.url(chatId, 'feedback'), body);
	}

	public dislikeAnswer(chatId: string, answerId: string): Promise<void> {
		const body: EvaluateChatBotRequest = {
			rating: 0,
			responseId: answerId,
		};
		return this.http.post<void>(this.url(chatId, 'feedback'), body);
	}

	public removeReaction(chatId: string, answerId: string): Promise<void> {
		const body: EvaluateChatBotRequest = {
			rating: null,
			responseId: answerId,
		};
		return this.http.post<void>(this.url(chatId, 'feedback'), body);
	}

	public getChats(origins: ChatBotChatOriginEnum[]): Promise<ChatBotChatInterface[]> {
		const params = {
			origins,
		};
		return this.http.get<ChatBotChatInterface[]>(this.url('/'), params);
	}

	public async createChat(request: CreateChatRequest): Promise<ChatBotChatInterface> {
		this.cleanCache();
		const response = await this.http.post<CreateChatBotChatResponse>(this.url('/'), request);
		return response.chat;
	}

	public editChat(chatId: string, request: UpdateChatRequest): Promise<ChatBotChatInterface> {
		this.cleanCache();
		return this.http.patch<ChatBotChatInterface>(this.url(chatId, '/'), request);
	}
}
