import {
	Directive,
	ElementRef,
	EventEmitter,
	HostListener,
	OnDestroy,
	Output,
} from '@angular/core';
import { fromEvent, merge, Subscription, timer, of } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

@Directive({
	selector: '[reachLongPress]',
	standalone: true,
	host: {
		'[class.disable-text-selection]': 'isTouchDevice',
	},
})
export class ReachLongPressDirective implements OnDestroy {
	@HostListener('contextmenu', ['$event'])
	public onContextMenu(event: MouseEvent): void {
		event.preventDefault();
	}
	private subscriptions = new Subscription();
	private threshold = 500;
	private isTouchDevice = 'ontouchstart' in window;

	@Output('reachLongPress') longPress = new EventEmitter<Event>();

	constructor(private elementRef: ElementRef) {
		if (this.isTouchDevice) {
			const pointerdown$ = fromEvent<TouchEvent>(elementRef.nativeElement, 'touchstart').pipe(
				map((event) => ({ event, start: true }))
			);

			this.subscriptions.add(
				fromEvent<MouseEvent>(elementRef.nativeElement, 'contextmenu').subscribe(
					(event) => {
						event.preventDefault();
					}
				)
			);

			this.subscriptions.add(
				pointerdown$
					.pipe(
						switchMap(({ event, start }) =>
							start
								? timer(this.threshold).pipe(
										map(() => event),
										takeUntil(
											merge(
												fromEvent(window, 'touchend'),
												fromEvent(window, 'touchmove'),
												fromEvent(window, 'scroll', {
													capture: true,
												})
											)
										)
								  )
								: of(null)
						),
						filter(Boolean)
					)
					.subscribe((event) => {
						this.longPress.emit(event);
					})
			);
		} else {
			const contextmenu$ = fromEvent<MouseEvent>(
				elementRef.nativeElement,
				'contextmenu'
			).pipe(
				tap((event) => event.preventDefault()),
				map((event) => ({ event, start: true }))
			);

			this.subscriptions.add(
				contextmenu$.pipe(map(({ event }) => event)).subscribe((event) => {
					this.longPress.emit(event);
				})
			);
		}
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}
}
