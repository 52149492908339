import { Injectable } from '@angular/core';
import {
	BooleanEnum,
	CanvasSearchResponse,
	OrderByEnum,
	RequestCanvasValidColumnNodeType,
	RequestPaginationInterface,
	SearchAITypeEnum,
	SearchNodeResponse,
	SearchParams,
	SearchResponse,
	SortKey,
} from '@reach/interfaces';
import { BaseApiService } from '~app-client/api/_internal';

@Injectable({ providedIn: 'root' })
export class SearchApiService extends BaseApiService {
	protected commonPath = ['search'];

	public search(
		query: SearchParams,
		pagination?: RequestPaginationInterface<SearchNodeResponse>,
		searchAIType?: SearchAITypeEnum
	): Promise<SearchResponse<BooleanEnum.NO>> {
		const somethingQueried = `${query.query || ''}`.trim() !== '';

		const defaultSortBy = somethingQueried ? SortKey.RELEVANCE : SortKey.DATE;

		const page = pagination?.page || 0;
		const size = pagination?.size || 30;
		const sortBy = (pagination?.sortBy as SortKey) || defaultSortBy;
		const orderBy = pagination?.orderBy || OrderByEnum.DESC;

		const body = {
			searchParams: query,
			page: page,
			size: size,
			sortBy: sortBy,
			orderBy: orderBy,
			searchAIType,
		};

		return this.http.post<SearchResponse<BooleanEnum.NO>>(this.url(), body);
	}

	public getAllIds(
		query: SearchParams,
		searchAIType?: SearchAITypeEnum
	): Promise<SearchResponse<BooleanEnum.YES>> {
		const body = {
			searchParams: query,
			searchAll: true,
			returnOnlyIds: BooleanEnum.YES,
			searchAIType,
		};

		return this.http.post<SearchResponse<BooleanEnum.YES>>(this.url(), body);
	}

	public getCanvas(
		query: SearchParams,
		columnNodeType: RequestCanvasValidColumnNodeType,
		pagination: RequestPaginationInterface<OrderByEnum>
	): Promise<CanvasSearchResponse> {
		const somethingQueried = `${query.query || ''}`.trim() !== '';

		const defaultSortBy = somethingQueried ? SortKey.RELEVANCE : SortKey.DATE;

		const page = pagination?.page || 0;
		const size = pagination?.size || 30;
		const sortBy = (pagination?.sortBy as SortKey) || defaultSortBy;
		const orderBy = pagination?.orderBy || OrderByEnum.DESC;

		const body = {
			searchParams: query,
			page: page,
			size: size,
			sortBy: sortBy,
			orderBy: orderBy,
			columnNodeType,
		};

		return this.http.post<CanvasSearchResponse>(this.url('canvas'), body);
	}
}
